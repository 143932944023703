import { useCallback, useState } from 'react'
import { faIdCard, faEye, faEdit } from '@fortawesome/free-solid-svg-icons'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'

import TSTable from 'components/Tanstack'

import { useNotesStore } from 'store/notes'
import { useTableUtils } from 'hooks/useTableUtils'
import { NotesDataTypes, NotesModalActionsTypes } from 'types/notes'

export const useMemberNotesTable = () => {
  const notes = useNotesStore((state) => state.notes)

  const {
    selectedModal,
    selectedModalSet,
    selectedRowData,
    selectedRowDataSet,
  } = useTableUtils<NotesDataTypes, NotesModalActionsTypes>()

  const handleActionModalClose = useCallback(() => {
    selectedRowDataSet(null)
    selectedModalSet(null)
  }, [selectedModalSet, selectedRowDataSet])

  const handleViewNoteModalOpen = useCallback(
    (rowData: NotesDataTypes) => {
      selectedRowDataSet(rowData)
      selectedModalSet('notes_preview')
    },
    [selectedRowDataSet, selectedModalSet]
  )

  const columnHelper = createColumnHelper<NotesDataTypes>()
  const columns = [
    columnHelper.accessor('noteDescription', {
      header: () => 'Notes/Memos',
      cell: (info) => {
        const noteDescription = info.getValue()
        const displayText =
          noteDescription && noteDescription.length > 35
            ? `${noteDescription.slice(0, 35)}...`
            : noteDescription || ''
            
        return (
          <p className="flex flex-col">
            <span>{displayText}</span>
          </p>
        )
      },
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('sourceName', {
      header: () => 'Source',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('lastModifiedDate', {
      header: () => 'Modified Date',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('noteType', {
      header: () => 'Type',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('priorityLevel', {
      header: () => 'Level',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.accessor('username', {
      header: () => 'Edited By',
      cell: (info) => info.getValue(),
      // footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => '',
      // cell: (info) => info.getValue(),
      cell: ({ row }) => (
        <div className="flex gap-2 px-4">
          <TSTable.TableCellButton
            onClick={() => {
              selectedRowDataSet(row.original)
              selectedModalSet('notes_update')
            }}
            label="Edit"
            icon={faEdit}
          />
          <TSTable.TableCellButton
            onClick={() => handleViewNoteModalOpen(row.original)}
            label="View"
            icon={faEye}
          />
        </div>
      ),
    }),
  ] as ColumnDef<NotesDataTypes>[]

  return {
    memberNotesSelected: selectedRowData,
    memberNotesActionModal: selectedModal,
    memberNotesTableData: notes,
    memberNotesTableColumns: columns,
    handleMemberActionModalClose: handleActionModalClose,
  }
}
